import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useContext } from 'react';
import { Title } from '../../inputs/input/styles';
import { CardStyled, RowItemStyled, RowStyled, RowWrapperStyled } from './styles';
import { LangContext } from '../../../context/lang.context';

interface PaymentMethodsProps {
  cards: {
    code: string;
    name: string;
    type: string;
  }[];
}

const PaymentMethods = ({ cards }: PaymentMethodsProps) => {
  const { t } = useContext(LangContext);

  const data = useStaticQuery(graphql`
    query {
      allI18NJson {
        nodes {
          locale
          clientId
          FORM {
            PAYMENT_METHODS
          }
        }
      }
      MASTERCARD: file(relativePath: { eq: "payment-methods/mastercard.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }

      MAESTRO: file(relativePath: { eq: "payment-methods/maestro.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }

      VISA: file(relativePath: { eq: "payment-methods/visa.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }

      VISADEBITO: file(relativePath: { eq: "payment-methods/visa-deb.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }

      AMEX: file(relativePath: { eq: "payment-methods/amex.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }

      CABALDEBITO: file(relativePath: { eq: "payment-methods/cabal-deb.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }

      MASTERDEBIT: file(relativePath: { eq: "payment-methods/mastercard-deb.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  `);

  const getImage = (code = '', name = '') => {
    const image = data[code];
    if (image) {
      return <GatsbyImage className="w-full" image={image.childImageSharp.gatsbyImageData} title={name} alt="Card" />;
    } else {
      const largerWord = name.split(' ').reduce((larger, word) => (larger.length > word.length ? larger : word), '');
      return (
        <div
          className={['w-full px-1', largerWord.length > 10 ? 'break-words' : ''].join(' ')}
          style={{ fontSize: 70 / Math.min(largerWord.length, 10) }}
        >
          {name}
        </div>
      );
    }
  };

  return (
    <>
      <Title className="mt-2">{t(data).FORM.PAYMENT_METHODS}</Title>
      <RowWrapperStyled>
        <RowStyled>
          {cards.map(item => (
            <RowItemStyled key={item.code}>
              <CardStyled>{getImage(item.code, item.name)}</CardStyled>
            </RowItemStyled>
          ))}
        </RowStyled>
      </RowWrapperStyled>
    </>
  );
};

export default PaymentMethods;
