import styled from '@emotion/styled';
import tw from 'twin.macro';
import { css } from '@emotion/react';

const RowWrapperStyled = styled.div`
  ${tw`overflow-auto max-w-screen-md mx-auto`}
`;

const RowStyled = styled.div`
  ${tw`flex`}
`;

const RowItemStyled = styled.div`
  ${tw`w-1/5 flex-none flex p-1 text-center`}
`;

const CardStyled = styled.div`
  ${tw`flex justify-center items-center text-center rounded shadow flex-1`}
`;

export { RowWrapperStyled, RowStyled, RowItemStyled, CardStyled };
