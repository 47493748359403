import React, { useContext } from 'react';
import { CenterContainer, Point1, Point2, Title } from './styles';
import Layout from '../responsive/layout';
import Topbar from '../responsive/topbar';
import { useStaticQuery, graphql } from 'gatsby';
import { LangContext } from '../../context/lang.context';

const LoadingComponent = () => {
  const { t } = useContext(LangContext);

  const data = useStaticQuery(graphql`
    query {
      allI18NJson {
        nodes {
          locale
          clientId
          LOADING {
            TITLE
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <Topbar />
      <div className="w-full flex justify-center">
        <CenterContainer>
          <Point1 color="middle" />
          <Point2 color="middle-100" />
          <Point1 color="light" />
          <Point2 color="light-100" />
          <Title>{t(data).LOADING.TITLE}</Title>
        </CenterContainer>
      </div>
    </Layout>
  );
};

LoadingComponent.defaultProps = {
  meta: {},
};

export { LoadingComponent };
