const validNameWithOutSpecialCharacteresRegex = new RegExp(/^[a-zA-Z ]+$/);
const validNameRegex = new RegExp(/^([A-ZÁÉÍÓÚa-zñáéíóú ]+)$/);
const validInternationalNameRegex = new RegExp(
  /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð`´ ,.'-]+$/
);
const validAliasRegex = new RegExp(/^[A-Za-z0-9.-]+$/);
const validLettersAndNumbersRegex = new RegExp(/^[A-Za-z0-9\s]+$/);
const validNumbersRegex = new RegExp(/^[0-9\s]+$/);
const validEmailRegex = new RegExp(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z-.]+$/);
const validMultiCompanyPath = new RegExp(/\/company\/\d*\/[a-z]{2}-[A-Z]{2}/);

export {
  validAliasRegex,
  validEmailRegex,
  validInternationalNameRegex,
  validLettersAndNumbersRegex,
  validNameRegex,
  validNameWithOutSpecialCharacteresRegex,
  validNumbersRegex,
  validMultiCompanyPath,
};
