import {
  validAliasRegex,
  validInternationalNameRegex,
  validLettersAndNumbersRegex,
  validNameWithOutSpecialCharacteresRegex,
  validNumbersRegex,
} from './regexs';

const MASK_CHAR_CARD = '\u2000';

const MASK_CARD = [
  /[0-9]/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

const MASK_EXPIRATION_DATE = [/[0-1]/, /\d/, '/', /[2-9]/, /[0-9]/];
const MASK_VERIFICATION_CODE = [/[0-9]/, /\d/, /\d/];
const MASK_VERIFICATION_CODE_4 = [/[0-9]/, /\d/, /\d/, /\d/];

type maskType = 'NAME' | 'NUMBER' | 'ALIAS' | 'NUMBER_CHAR' | 'PASSPORT';

const createMask = (type: maskType, count: number = 1) => {
  if (type === 'PASSPORT') {
    return [
      validNameWithOutSpecialCharacteresRegex,
      validNameWithOutSpecialCharacteresRegex,
      validNameWithOutSpecialCharacteresRegex,
      validNumbersRegex,
      validNumbersRegex,
      validNumbersRegex,
      validNumbersRegex,
      validNumbersRegex,
      validNumbersRegex,
    ];
  }

  const typeRegex = {
    NAME: validInternationalNameRegex,
    NUMBER: validNumbersRegex,
    ALIAS: validAliasRegex,
    NUMBER_CHAR: validLettersAndNumbersRegex,
  };

  return Array(count).fill(typeRegex[type]);
};

export {
  MASK_CARD,
  MASK_CHAR_CARD,
  MASK_EXPIRATION_DATE,
  MASK_VERIFICATION_CODE_4,
  MASK_VERIFICATION_CODE,
  createMask,
};
